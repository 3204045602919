import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Makroekologické vlastnosti areálů" />
    <h1>Makroekologické vlastnosti areálů</h1>
    <p>Makroekologick&eacute; vlastnosti are&aacute;lů jsou z&aacute;kladn&iacute; okruhem z&aacute;jmu <Link to="/biogeografie/areografie/">areografie</Link>, tedy biogeografick&eacute;ho studia are&aacute;lů taxonů. Nejčastěji jsou studov&aacute;ny vztahy mezi velikost&iacute; a tvarem are&aacute;lů a vlastnostmi studovan&yacute;ch taxonů.</p>
    <p>Are&aacute;lem se rozum&iacute; v tomto kontextu oblast roz&scaron;&iacute;řen&iacute;, ve kter&eacute;m se objevuje určit&yacute; taxon (druh, rod apod.).</p>
    <hr />
    <h2>Velikost are&aacute;lu</h2>
    <p>Velikost are&aacute;lu je jedn&iacute;m z nejjednodu&scaron;&scaron;&iacute;ch parametrů v areografii, kter&yacute; lze sledovat. Obecně lze ř&iacute;ci, že jen m&aacute;lo druhů m&aacute; relativně velk&eacute; are&aacute;ly, statistick&eacute; rozložen&iacute; ukazuje vy&scaron;&scaron;&iacute; četnost druhů s relativně mal&yacute;mi are&aacute;ly.</p>
    <p>Pt&aacute;ci m&iacute;vaj&iacute; are&aacute;l největ&scaron;&iacute;, ve velikosti are&aacute;lu n&aacute;sleduj&iacute; savci, pot&eacute; sladkovodn&iacute; ryby a obojživeln&iacute;ci a nejmen&scaron;&iacute; are&aacute;ly jsou zaznamen&aacute;v&aacute;ny u hmyzu a rostlin.</p>
    <h3>Rapoportovo pravidlo</h3>
    <p>Rapoportovo pravidlo popisuje, že are&aacute;ly druhů se zvět&scaron;uj&iacute; se vzrůstaj&iacute;c&iacute; zeměpisnou &scaron;&iacute;řkou. Toto pravidlo plat&iacute; analogicky i pro hloubku v oce&aacute;nech.</p>
    <p>Jedno z vysvětlen&iacute; Rapoportova pravidla může b&yacute;t vliv klimatu, a to zejm&eacute;na sez&oacute;nn&iacute; variability. Druhy vy&scaron;&scaron;&iacute;ch &scaron;&iacute;řek tak toleruj&iacute; vět&scaron;&iacute; kol&iacute;s&aacute;n&iacute; teplot a mohou tak obsazovat vět&scaron;&iacute; are&aacute;ly.</p>
    <p>Dal&scaron;&iacute;mi vysvětlen&iacute;mi pak může b&yacute;t vliv pleistocenn&iacute;ho zaledněn&iacute;, vliv m&iacute;ry extinkce (druhy s n&iacute;zkou toleranc&iacute; ve vy&scaron;&scaron;&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch vymřou). Pravidlo se d&aacute; tak&eacute; vysvětlit vlivem vy&scaron;&scaron;&iacute; <Link to="/biogeografie/ekologicka/bioticke-interakce/">kompetice</Link> v tropech a vlivem m&iacute;ry <Link to="/biogeografie/evolucni/speciace/">speciace</Link>.</p>
    <h3>Vztah velikosti are&aacute;lu a&nbsp;velikosti těla</h3>
    <p>U vztahu velikosti are&aacute;lu a&nbsp;velikosti těla dan&eacute;ho druhu neplat&iacute; př&iacute;m&aacute; &uacute;měra. Zat&iacute;mco mal&eacute; organismy maj&iacute; mal&eacute; i velk&eacute; are&aacute;ly, velk&eacute; organismy maj&iacute; zpravidla pouze velk&eacute; are&aacute;ly. Plat&iacute; tak zde tzv. troj&uacute;heln&iacute;kov&yacute; vztah.</p>
    <h3>Vztah velikosti are&aacute;lu a&nbsp;abundance druhu</h3>
    <p>Druhy s vět&scaron;&iacute;m are&aacute;lem maj&iacute; zpravidla i vy&scaron;&scaron;&iacute; abundanci, tedy se vyskytuj&iacute; ve vy&scaron;&scaron;&iacute;ch počtech.</p>
    <p>Druhy se <Link to="/biogeografie/ekologicka/ekologicke-faktory/">&scaron;irokou ekologickou nikou</Link> mohou využ&iacute;t &scaron;irokou nab&iacute;dku prostřed&iacute; a zdrojů, což těmto druhům umožňuje m&iacute;t velk&yacute; geografick&yacute; are&aacute;l a&nbsp;vysok&eacute; populačn&iacute; hustoty.</p>
    <p>Někter&eacute; druhy v&scaron;ak maj&iacute; velk&eacute; are&aacute;ly a n&iacute;zk&eacute; abundance, což ukazuje opět na troj&uacute;heln&iacute;kov&yacute; vztah. To je způsobeno pravděpodobně lep&scaron;&iacute; schopnost&iacute; <Link to="/biogeografie/disperze/">disperse</Link>.</p>
    <hr />
    <h2>Tvar are&aacute;lů</h2>
    <p>Při sledov&aacute;n&iacute; tvaru are&aacute;lů směřuje pozornost zejm&eacute;na na rozd&iacute;ly v latitudin&aacute;ln&iacute;m (sever-jih) a longitudin&aacute;ln&iacute;m směru (v&yacute;chod-z&aacute;pad). Obecně v&iacute;ce protažen&eacute; are&aacute;ly se vyskytuj&iacute; v&iacute;ce v severn&iacute;ch zeměpisn&yacute;ch &scaron;&iacute;řk&aacute;ch. Zpravidla plat&iacute; &uacute;měra, že č&iacute;m vět&scaron;&iacute; are&aacute;l dan&eacute;ho taxonu je, t&iacute;m protaženěj&scaron;&iacute; tvar m&aacute;.</p>
    <p>D&aacute;le jsou obvykle velk&eacute; are&aacute;ly protaženy v longitudin&aacute;ln&iacute;m směru (v&yacute;chod-z&aacute;pad), zejm&eacute;na na severn&iacute; polokouli. Mal&eacute; are&aacute;ly, pokud jsou př&iacute;tomny, pak b&yacute;vaj&iacute; protažena latitudin&aacute;lně (sever-jih), a to předev&scaron;&iacute;m na jižn&iacute; polokouli. Možn&eacute; vysvětlen&iacute; je longitudin&aacute;ln&iacute; protažen&iacute; klimatick&yacute;ch (a t&iacute;m i vegetačn&iacute;ch) p&aacute;sů. Dal&scaron;&iacute;m vysvětlen&iacute;m je pak tvar kontinentů a poloha bari&eacute;r (jako je <Link to="/hydrologie/svetovy-ocean/">světov&yacute; oce&aacute;n</Link> či horsk&aacute; p&aacute;sma).</p>
    <h3>Obsazenost are&aacute;lu</h3>
    <p>Obsazenost are&aacute;lu (anglicky <em>occupancy</em>) popisuje v biogeografii a ekologii, kolik lokalit v r&aacute;mci are&aacute;lu druh skutečně ob&yacute;v&aacute;. Druh totiž neob&yacute;v&aacute; &uacute;plně v&scaron;echny lokality uvnitř sv&eacute;ho are&aacute;lu a vyskytuj&iacute; se v něm mezery. Vnitřn&iacute; struktura are&aacute;lu je agregovan&aacute; s prostorovou autokorelac&iacute;.</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Makroekologické vlastnosti areálů</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/areografie/"><button className="inv">&larr; Areografie</button></Link>
    <Link to="/biogeografie/areografie/faktory-ovlivnujici-rozsireni-druhu/"><button className="inv">Faktory ovlivňující rozšíření druhů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
